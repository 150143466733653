import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { UserAccountDTO, UserSavedXploreDTO } from "../../shared/object/xplorer-be-parsed-classes";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ToastService } from "../toast/toast.service";
import { ToastTextType } from "../../shared/object/toast";
import { TokenStorageService } from "../auth/token-storage.service";
import {AuthToken} from "../../shared/object/keycloak-classes";
import {AnalyticsService} from "../analytics/analytics.service";

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};


@Injectable({
  providedIn: 'root',
})
export class UserService {
  private favourtiesKey = 'user-favorites'
  private favorites: number[] = [];

  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    private toastService: ToastService,
    private analyticsService: AnalyticsService
  ) {
  }

  initSavedXplore() {
    const user: UserAccountDTO = this.tokenStorageService.getUser();

    if (user && user.userId) {
      this.http.get<UserSavedXploreDTO[]>(`${environment.API_URL}/saved-xplore`).subscribe(
        savedItems => {
          if (savedItems?.length > 0) {
            this.favorites = savedItems.map(savedItem => savedItem.xploreId);
            const jsonSavedItems = JSON.stringify(this.favorites)
            localStorage.setItem(this.favourtiesKey, jsonSavedItems)
          } else {
            this.favorites = [];
          }
        });
    }
  }

  addSavedXplore(id: number) {
    this.analyticsService.saveLikeAction(id);
    return this.http.post<UserSavedXploreDTO>(`${environment.API_URL}/saved-xplore/xplore/${id}`, {}).subscribe({
      next: savedItem => {
        if (savedItem) {
          this.favorites.push(savedItem.xploreId);
          const jsonSavedItems = JSON.stringify(this.favorites)
          localStorage.setItem(this.favourtiesKey, jsonSavedItems);
          this.toastService.showSuccessToast(ToastTextType.ADDED_TO_FAVORITES);
        }
      },
      error: error => {
        this.toastService.showErrorToast(`Error: ${error.error.message}`);
      }
    });
  }

  removeFavorite(id: number) {
    this.http.delete<number>(`${environment.API_URL}/saved-xplore/xplore/${id}`).subscribe(
      data => {
        if (data == id) {
          this.favorites = this.favorites.filter(favoriteId => favoriteId != id);
          const jsonSavedItems = JSON.stringify(this.favorites);
          localStorage.setItem(this.favourtiesKey, jsonSavedItems);
          this.toastService.showSuccessToast(ToastTextType.REMOVED_FROM_FAVORITES);
        }
      }
    );
  }

  checkIfItemInFavorite(id: number) {
    return this.favorites?.includes(id);
  }

  getFavorites(): Observable<UserSavedXploreDTO[]> {
    return this.http.get<UserSavedXploreDTO[]>(`${environment.API_URL}/saved-xplore`);
  }

  getCurrentUser(): Observable<UserAccountDTO> {
    return this.http.get<UserAccountDTO>(`${environment.API_URL}/user/me`, httpOptions);
  }

  saveUserInfo(userInfo: UserAccountDTO): Observable<UserAccountDTO> {
    return this.http.post<UserAccountDTO>(`${environment.API_URL}/user/save-user-info`, userInfo);
  }

  updateDisplayedName(displayedName: string) {
    return this.http.put<UserAccountDTO>(`${environment.API_URL}/user/update/displayed-name`, displayedName, httpOptions);
  }

  updateInstagramUsername(instagramUsername: string) {
    return this.http.put<UserAccountDTO>(`${environment.API_URL}/user/update/instagram-username`, instagramUsername, httpOptions);
  }
}
