<ng-template #popup>
  <div class="modal-header">
    <h4 *ngIf="alreadyReserved; else notReservedTitle" class="modal-title">
      {{ 'event.cancel-title' | translate }}
    </h4>
    <ng-template #notReservedTitle>
      <h4 class="modal-title">
        {{ 'event.confirm-title' | translate }}
      </h4>
    </ng-template>
    <button
      type="button"
      class="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg p-1.5"
      aria-describedby="modal-title"
      (click)="closeModal()">
      <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              fill-rule="evenodd">
        </path>
      </svg>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!isLoading">
      <div *ngIf="alreadyReserved; else notReserved">
        <p>
          {{ 'event.cancel-message' | translate }} <strong>{{xploreItem.name}}</strong>?
        </p>
      </div>
      <ng-template #notReserved>
        <form [formGroup]="reservationFormGroup">
          <div class="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <label for="first_name" class="block mb-2 text-sm font-medium">{{ 'event.reservation.name' | translate}}</label>
              <input formControlName="name"
                     type="text" id="first_name" class="border border-gray-300 text-sm rounded-lg block w-full p-2.5"
                     placeholder="{{ 'event.reservation.name_placeholder' | translate}}" required>
              <div *ngIf="shouldShowError('name')" class="text-red-500 text-xs italic">
                {{ 'event.reservation.name_error' | translate}}
              </div>
            </div>
            <div>
              <label for="last_name" class="block mb-2 text-sm font-medium">{{ 'event.reservation.surname' | translate}}</label>
              <input formControlName="surname"
                     type="text" id="last_name" class="border border-gray-300 text-sm rounded-lg block w-full p-2.5"
                     placeholder="{{ 'event.reservation.surname_placeholder' | translate}}" required>
              <div *ngIf="shouldShowError('surname')" class="text-red-500 text-xs italic">
                {{ 'event.reservation.surname_error' | translate}}
              </div>
            </div>
            <div>
              <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-whit">
                {{ 'event.reservation.phone' | translate}}
              </label>
              <input formControlName="phoneNumber"
                     type="tel" id="phone" class="border border-gray-300 text-sm rounded-lg block w-full p-2.5"
                     placeholder="{{ 'event.reservation.phone_placeholder' | translate}}">
              <div *ngIf="shouldShowError('phoneNumber')" class="text-red-500 text-xs italic">
                {{ 'event.reservation.phone_error' | translate}}
              </div>
            </div>
            <div>
              <label for="additionalNumberOfPeople" class="block mb-2 text-sm font-medium text-gray-900">
                {{ 'event.reservation.additional_number_of_people' | translate}}
              </label>
              <select [ngClass]="{'disabled pe-none': actualEvent.multipleReservationsAllowed === false}"
                      [disabled]="actualEvent.multipleReservationsAllowed === false"
                      formControlName="additionalNumberOfPeople"
                      id="additionalNumberOfPeople"
                      class="border border-gray-300 text-sm rounded-lg block w-full p-2.5">
                <option [selected]="actualEvent.multipleReservationsAllowed === false" value=0>
                  {{ 'event.reservation.multiple_reservations_not_allowed' | translate}}
                </option>
                <option [selected]="actualEvent.multipleReservationsAllowed === true" value=0>
                  {{ 'event.reservation.additional_number_of_people_zero' | translate}}
                </option>
                <option value=1>1 {{ 'event.reservation.additional_number_of_people_single' | translate}}</option>
                <option value=2>2 {{ 'event.reservation.additional_number_of_people_multiple' | translate}}</option>
                <option value=3>3 {{ 'event.reservation.additional_number_of_people_multiple' | translate}}</option>
                <option value=4>4 {{ 'event.reservation.additional_number_of_people_multiple' | translate}}</option>
                <option value=5>5 {{ 'event.reservation.additional_number_of_people_multiple' | translate}}</option>
                <option value=6>6 {{ 'event.reservation.additional_number_of_people_multiple' | translate}}</option>
                <option value=7>7 {{ 'event.reservation.additional_number_of_people_multiple' | translate}}</option>
                <option value=8>8 {{ 'event.reservation.additional_number_of_people_multiple' | translate}}</option>
                <option value=9>9 {{ 'event.reservation.additional_number_of_people_multiple' | translate}}</option>
              </select>
            </div>
          </div>
          <div>
            <label for="additionalInfo" class="block mb-2 text-sm font-medium text-gray-900">
              {{ 'event.reservation.additional_info' | translate}}
            </label>
            <textarea formControlName="additionalInfo"
                      id="additionalInfo" class="border border-gray-300 text-sm rounded-lg block w-full p-2.5"
                      maxlength="300"
                      placeholder="{{ 'event.reservation.additional_info_placeholder' | translate}}"
                      style="max-height: 200px">
            </textarea>
          </div>
        </form>
      </ng-template>
      <div class="modal-footer border-0">
        <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">
          {{ 'event.reservation.abort' | translate}}
        </button>
        <button *ngIf="!alreadyReserved" [disabled]="reservationFormGroup.invalid"

                type="button" class="custom-color py-2 px-3 rounded"
                (click)="submitReservation(reservationFormGroup)">
          {{ 'event.reservation.confirm' | translate}}
        </button>
        <button *ngIf="alreadyReserved" type="button" class="custom-color py-2 px-3 rounded"
                (click)="deleteReservation()">
          {{ 'event.reservation.cancel' | translate}}
        </button>
      </div>
    </div>

    <div *ngIf="isLoading" class="w-100 d-flex justify-content-center">
      <div class="spinner-border custom-spinner" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</ng-template>
